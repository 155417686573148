html, body {
  margin:0px;
  background-color: #000;
}

.floatInsta{
	position:fixed;
	width:40px;
	height:40px;
	bottom:15px;
	right:10px;
  color: #FFF;
  cursor: pointer;
}

.floatMail{
	position:fixed;
	width:40px;
	height:40px;
	bottom:15px;
	right:50px;
  color: #FFF;
  cursor: pointer;
}