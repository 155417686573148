@font-face {
  font-family: 'Timer';
  src: url(../assets/fonts/PorSiempreGotica.ttf)
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.timerBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  font-size: xx-large;
  font-family: Timer;
  text-align: center;
}

p {
  color: white;
  margin-bottom: 5vh;
}